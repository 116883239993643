import { useStaticQuery, graphql } from "gatsby"

export const useLocatorsQuery = () => {


    const data = useStaticQuery(graphql`
    query LocatorsQuery {
        wpPage(title: {eq: "LOCATORS"}) {
         ACF_LocatorsPage {

            locatorsPageHero {
                image {
                    publicUrl
                }
                text
                tagline
            }

            locatorsPageColumns {
                column1 {
                    image {
                        publicUrl
                    }
                    title
                    content
                    ctaLabel
                    ctaUrl
                }
                column2 {
                    image {
                        publicUrl
                    }
                    title
                    content
                    ctaLabel
                    ctaUrl
                }
            }

            locatorsPageMeta{
                metaTitle
                metaDescription
            }
        
        }
        }
    }     
    `)
    return data.wpPage.ACF_LocatorsPage;
}