import * as React from "react";

const MainContent = ({data}) => {
    return (
        <div>
            <div className="row-fluid-wrapper row-depth-1 row-number-7 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-custom_widget hidden-phone" style={{ height: "20px" }} data-widget-type="custom_widget" data-x="0" data-w="12">
                        <div id="hs_cos_wrapper_module_1555527537599195" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                    </div>
                </div>
            </div>

            <div className="row-fluid-wrapper row-depth-1 row-number-8 ">
                <div className="row-fluid ">
                    <div className="span12 widget-span widget-type-cell full-width section--main" data-widget-type="cell" data-x="0" data-w="12">

                        <div className="row-fluid-wrapper row-depth-1 row-number-9 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-cell " data-widget-type="cell" data-x="0" data-w="12">

                                    <div className="row-fluid-wrapper row-depth-1 row-number-10 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1576702088978667" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row-fluid-wrapper row-depth-1 row-number-11 ">
                                        <div className="row-fluid ">
                                            <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                                <div id="hs_cos_wrapper_module_1555527537599196" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module" data-hs-cos-general-type="widget" data-hs-cos-type="module"><div className="locatorsWrapper">

                                                    <div className="locatorsBoxes">
                                                        <img style={{width:"100%"}} src={data.column1.image.publicUrl} />
                                                        <div className="locatorsContent clearfix nopadding"><h3 style={{textAlign: "center"}}><span style={{color: "#ffffff"}} dangerouslySetInnerHTML={{__html: data.column1.title}}></span></h3>
                                                            <div className="sp_home_cta_copy" style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: data.column1.content}}>
                                                            </div>
                                                            <div className="sp_home_cta_btns"><span className="hs-cta-wrapper" id="hs-cta-wrapper-af63a61e-3805-4c01-beaf-ef23bf496e53"><span className="hs-cta-node hs-cta-af63a61e-3805-4c01-beaf-ef23bf496e53" id="hs-cta-af63a61e-3805-4c01-beaf-ef23bf496e53" style={{visibility: "visible"}} data-hs-drop="true"><a id="cta_button_2642996_a86eb4f6-915b-47dd-a31d-caaca9d27372" className="cta_button sp_grey_btn" href={data.column1.ctaUrl} title="Find a Physician Now" dangerouslySetInnerHTML={{__html: data.column1.ctaLabel}}></a></span></span></div></div>
                                                    </div>

                                                    <div className="locatorsBoxes">
                                                        <img style={{width:"100%"}} src={data.column2.image.publicUrl} />
                                                        <div className="locatorsContent clearfix nopadding"> <h3 style={{textAlign: "center"}}><span style={{color: "#ffffff"}} dangerouslySetInnerHTML={{__html: data.column2.title}}></span></h3>
                                                            <div className="sp_home_cta_copy" style={{textAlign: "center"}} dangerouslySetInnerHTML={{__html: data.column2.content}}>
                                                               
                                                            </div>
                                                            <div className="sp_home_cta_btns"><span className="hs-cta-wrapper" id="hs-cta-wrapper-49cbff5a-5665-4a79-8b66-19d854a2ec84"><span className="hs-cta-node hs-cta-49cbff5a-5665-4a79-8b66-19d854a2ec84" id="hs-cta-49cbff5a-5665-4a79-8b66-19d854a2ec84" style={{visibility: "visible"}} data-hs-drop="true"><a id="cta_button_2642996_81fef1f3-8c2a-48a0-9100-654551da2084" className="cta_button sp_grey_btn" href={data.column2.ctaUrl} title="Find a 4Kscore Draw Center" dangerouslySetInnerHTML={{__html: data.column2.ctaLabel}}></a></span></span></div> </div>
                                                    </div>

                                                </div></div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="row-fluid-wrapper row-depth-1 row-number-12 ">
                            <div className="row-fluid ">
                                <div className="span12 widget-span widget-type-custom_widget " data-widget-type="custom_widget" data-x="0" data-w="12">
                                    <div id="hs_cos_wrapper_module_1536028948607173" className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-space" data-hs-cos-general-type="widget" data-hs-cos-type="module"><span className="hs-horizontal-spacer"></span></div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainContent;