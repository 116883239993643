import * as React from "react";
import Layout from "../components/global/Layout";
import Hero from "../components/locators/Hero";
import MainContent from "../components/locators/MainContent";
import { useLocatorsQuery } from "../hooks/useLocatorsQuery";
import Helmet from "react-helmet";

const LocatorsPage = () => {
    const data = useLocatorsQuery();
    return (
        <Layout>
            <Hero data={data.locatorsPageHero} />
            <MainContent data={data.locatorsPageColumns} />
            <Helmet>
                <title>{data.locatorsPageMeta.metaTitle}</title>
                <meta name="description" content={data.locatorsPageMeta.metaDescription} />
            </Helmet>
        </Layout>
    )
}

export default LocatorsPage;